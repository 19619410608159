import React, { useState } from "react";
import '../css/Collapsible.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faLock } from '@fortawesome/free-solid-svg-icons';

const Collapsible = ({ children, title, admin=false, nowe=false }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDiv = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div style={{display:'flex', alignItems:'center',gap:'10px'}}>{nowe ? <div style={{color:'lime', fontWeight:'bold'}}>NEW</div> : ''}</div>
      <div id="collapsible-button" onClick={toggleDiv}>
        <div>{title}</div>
        <div style={{display:'flex', alignItems:'center',gap:'10px'}}>{admin ? <div className="tooltip-icon"><FontAwesomeIcon icon={faLock} /><span className="tooltip">Wymaga uprawnień administratora</span></div> : ''}
        <FontAwesomeIcon
          icon={faChevronDown}
          style={{
            color: "#ffffff",
            display: 'block',
            transition: "transform 0.3s ease", // Smooth rotation
            transform: isOpen ? "rotate(180deg)" : "rotate(0deg)" // Conditional rotation
          }}
        />
        </div>
      </div>
      
      {isOpen && (
        <div className="collapsible-content">
          { children }
        </div>
      )}
    </div>
  );
};

export default Collapsible;
