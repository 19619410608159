import React from 'react';
import '../css/MainWindow.css';
import '../css/Articles.css';
import Collapsible from '../components/Collapsible';
import Command from "../components/Command";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs, vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTurnUp } from '@fortawesome/free-solid-svg-icons';
import ResultImage from '../components/ResultImage';
import KeyShortcut from '../components/KeyShortcut';
import zadanieData from './zadanie.json';
import zadanie2 from './zadanie2.json';
import zadanie3 from './zadanie3.json';
import { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const SSO = () => {
    const [tasks, setTasks] = useState([]);
    const [zadania2, setZadania2] = useState([]);
    const [zadania3, setZadania3] = useState([]);

    useEffect(() => {
      // Ładowanie danych JSON (jeśli dane są w pliku z folderu src)
      setTasks(zadanieData.tasks);
      setZadania2(zadanie2.tasks);
      setZadania3(zadanie3.tasks);
    }, []);

    return (
    <div id="container">
        <div id="banner" style={{height:'100px',alignItems:'flex-start',padding:'0 60px'}}>
            <h1>Sieciowe Systemy Operacyjne</h1>
        </div>
      
        <div id="article-wrapper" className='column'>
            <h1 style={{alignSelf: 'flex-start'}}>Systemy liczbowe</h1>


            <Collapsible title="Systemy liczbowe">
                <p>Systemy liczbowe to sposoby reprezentowania liczb przy użyciu różnych podstaw (bazy) i zestawów symboli. Każdy system liczbowy opiera się na określonej bazie, która definiuje, ile różnych cyfr można używać w zapisie liczby.</p>
                <p>Oto najpopularniejsze systemy liczbowe:
                    <ul>
                        <li><b>System dziesiętny</b> (decymalny) – system o podstawie 10, używany powszechnie w życiu codziennym. Wykorzystuje cyfry od <Command>0</Command> do <Command>9</Command>.</li>
                        <li><b>System dwójkowy</b> (binarny)– system o podstawie 2, używany w komputerach i elektronice, gdzie liczby zapisuje się za pomocą cyfr <Command>0</Command> i <Command>1</Command>.</li>
                        <li><b>System ósemkowy</b> (oktalny) – system o podstawie 8, używa cyfr od <Command>0</Command> do <Command>7</Command>. Bywa stosowany w informatyce jako uproszczenie zapisu binarnego.</li>
                        <li><b>System szesnastkowy</b> (heksadecymalny) – system o podstawie 16, używający cyfr od <Command>0</Command> do <Command>9</Command> oraz liter od <Command>A</Command> do <Command>F</Command>, gdzie <Command>A</Command> odpowiada wartości <Command>10</Command>, <Command>B</Command> – <Command>11</Command>, i tak dalej, aż do <Command>F</Command>, czyli <Command>15</Command>.</li>
                    </ul>
                    </p>
                    <p>Każda cyfra w danym systemie ma wagę zależną od swojej pozycji. Wartość liczby oblicza się, mnożąc każdą cyfrę przez odpowiednią potęgę podstawy i sumując te wyniki.</p>
            </Collapsible>

            <Collapsible title="Dwójkowy system liczbowy">
                <p>System dwójkowy, zwany także systemem binarnym, to system liczbowy o podstawie <Command>2</Command>. Używa tylko dwóch cyfr: <Command>0</Command> i <Command>1</Command>, więc każda liczba w systemie binarnym jest ciągiem zer i jedynek. System ten jest bardzo istotny w informatyce i elektronice, ponieważ komputery przechowują i przetwarzają dane w postaci binarnej. Każdy bit (najmniejsza jednostka informacji) może przyjąć wartość <Command>0</Command> lub <Command>1</Command>, co odpowiada dwóm stanom w elektronice (np. wyłączony/włączony, niski/wysoki poziom napięcia).</p>
                <h3>Zamiana liczby binarnej na dziesiętną</h3>
                <p>Załóżmy, że mamy liczbę binarną <Command>1011<sub>2</sub></Command>. W systemie dwójkowym każda pozycja ma swoją wartość, która jest potęgą liczby 2. Zaczynamy od prawej strony:</p>
                <table className='binary-table'>
                    <tr><td>Liczba binarna</td><td style={{backgroundColor: '#ffff0055'}}>1</td><td style={{backgroundColor: '#ff000055'}}>0</td><td style={{backgroundColor: '#00ff0055'}}>1</td><td style={{backgroundColor: '#0000ff55'}}>1</td></tr>
                    <tr><td>Wartość pozycji</td><td style={{backgroundColor: '#ffff0055'}}>2<sup>3</sup></td><td style={{backgroundColor: '#ff000055'}}>2<sup>2</sup></td><td style={{backgroundColor: '#00ff0055'}}>2<sup>1</sup></td><td style={{backgroundColor: '#0000ff55'}}>2<sup>0</sup></td></tr>
                </table>
                <p style={{fontWeight:'500'}}><span style={{backgroundColor: '#ffff0055'}}>1 × 2<sup>3</sup></span> + <span style={{backgroundColor: '#ff000055'}}>0 × 2<sup>2</sup></span> + <span style={{backgroundColor: '#00ff0055'}}>1 × 2<sup>1</sup></span> + <span style={{backgroundColor: '#0000ff55'}}>1 × 2<sup>0</sup></span> = 8 + 0 + 2 + 1 = <u>11</u></p>
                <div className='split-view'>
                    <div style={{minWidth:'200px'}}>
                        <h3>Zamiana liczby dzisiętnej na binarną</h3>
                        <p>Aby zamienić liczbę dziesiętną na binarną, można użyć metody dzielenia przez 2:</p>
                            <ol>
                                <li>Dzielimy liczbę przez 2, zapisując resztę (0 lub 1).</li>
                                <li>Kontynuujemy dzielenie całkowite aż do uzyskania ilorazu równego 0.</li>
                                <li>Wynik zapisujemy od końca.</li>
                            </ol>
                        <p>Dzielenie liczby dziesiętnej można przedstawić na "słupku", który po lewej stronie ma wynik dzielenia liczby przez 2, zaokrąglając w dół, a po prawej stronie ma resztę z dzielenia przez 2.</p>
                    </div>
                    <div>
                    <p>Przykład zamiany liczby <Command>25</Command> na system binarny:</p>
                    <table className='binary-to-decimal-table'>
                        <tr><td colSpan={2}>÷ 2</td></tr>
                        <tr><td>25</td><td>1</td></tr>
                        <tr><td>12</td><td>0</td></tr>
                        <tr><td>6</td><td>0</td></tr>
                        <tr><td>3</td><td>1</td></tr>
                        <tr><td>1</td><td>1</td></tr>
                        <tr><td>0</td><td><FontAwesomeIcon icon={faArrowTurnUp} style={{color: "#ffffff",}} /></td></tr>
                    </table>
                    <p style={{color:'#ff0000',fontWeight:'bold'}}>Wynik odczytujemy z dołu do góry (od końca) zatem liczba <Command>25<sub>10</sub></Command> jest równa liczbie <Command>11001<sub>2</sub></Command>.</p>
                    </div>
                </div>
            </Collapsible>

            <Collapsible title="Ósemkowy system liczbowy">
                <p>System ósemkowy, zwany także systemem oktalnym, to system liczbowy o podstawie <Command>8</Command>, w którym używa się ośmiu cyfr: <Command>0-7</Command>. Każda pozycja w liczbie ósemkowej reprezentuje potęgę liczby <Command>8</Command>, podobnie jak w systemie dziesiętnym liczby reprezentują potęgi liczby 10.</p>
                <p>W systemie ósemkowym wartości kolejnych pozycji rosną jako kolejne potęgi liczby <Command>8</Command>, czyli: <Command>8<sup>0</sup>, 8<sup>1</sup>, 8<sup>2</sup>, 8<sup>3</sup>, ... </Command>. Wartość liczby oblicza się poprzez przemnożenie każdej cyfry przez odpowiadającą jej potęgę <Command>8</Command> i zsumowanie wyników.</p>
                <h3>Zastosowanie systemu ósemkowego</h3>
                <p>System ósemkowy jest często używany w informatyce do skracania zapisu binarnego (trzy bity można zapisać jako jedną cyfrę ósemkową).</p>
                <h3>Zamiana liczby dziesiętnej na ósemkową</h3>
                <p>Zamianę liczby dziesiętnej na oktalną możemy zrobić na 2 sposoby:</p>
                
                <div className='split-view'>
                    <div style={{minWidth:'200px'}}>
                        <h3 style={{textAlign:'center'}}>Zamiana metodą słupkową</h3>
                        <p>Tak samo jak w systemie dwójkowym możemy użyć metody "słupkowej" z jedną różnicą - dzielimy przez <Command>8</Command>.</p>
                        <p>Załóżmy, że mamy liczę <Command>83<sub>10</sub></Command></p>
                        <table className='binary-to-decimal-table'>
                            <tr><td colSpan={2}>÷ 8</td></tr>
                            <tr><td>83</td><td>3</td></tr>
                            <tr><td>10</td><td>2</td></tr>
                            <tr><td>1</td><td>1</td></tr>
                            <tr><td>0</td><td><FontAwesomeIcon icon={faArrowTurnUp} style={{color: "#ffffff",}} /></td></tr>
                        </table>
                        <p>Tak samo jak w dwójkowym - wynik z metody słupkowej zapisujemy <b>z dołu do góry</b>, zatem liczba <Command>83<sub>10</sub></Command> jest równa liczbie <Command>123<sub>8</sub></Command>.</p>
                    </div>
                    <div>
                        <h3 style={{textAlign:'center'}}>Zamiana poprzez grupowanie bitów</h3>
                        <p>Drugim sposobem na zamianę liczy dziesiętnej na ósemkową jest pierw zamienienie liczby dziesiętnej na binarną, a następnie zgrupowanie bitów po 3 i zamianę tych grup na liczbę dziesiętną. Każda grupa to jedna cyfra liczby oktalnej.</p>
                        <p>Załózmy, że mamy liczbę <Command>235<sub>10</sub></Command> = <Command>11111110<sub>2</sub></Command>.</p>
                        <table className='binary-table'>
                            <tr><td>Liczba binarna</td><td>0</td><td>1</td><td>1</td><td>1</td><td>1</td><td>1</td><td>1</td><td>1</td><td>0</td></tr>
                            <tr style={{letterSpacing:'5px'}}><td style={{letterSpacing:'normal'}}>Grupy bitów</td><td colSpan={3}>0 1 1</td><td colSpan={3}>1 1 1</td><td colSpan={3}>1 1 0</td></tr>
                            <tr><td>Zamiana na dziesiętną</td><td colSpan={3}>3</td><td colSpan={3}>7</td><td colSpan={3}>6</td></tr>
                        </table>
                        <p>Zatem liczba <Command>235<sub>10</sub></Command> jest równa <Command>376<sub>8</sub></Command>. <b>Ważne!</b> Grupowanie bitów zaczynamy od prawej strony! Jeżeli w ostatniej grupie mamy 1 lub 2 bity zamiast potrzebnych 3 do grupy to możemy dopisać 0, tak ajk w przypadku powyżej. Dodanie zera po lewej stronie nie zmieni wartości liczby binarnej!</p>
                    </div>
                </div>
            </Collapsible>


            <h1 style={{alignSelf: 'flex-start'}}>Windows</h1>

            <Collapsible title="Partycje w systemie Windows" nowe admin>
                <p>Partycje w systemie Windows to logiczne podziały na dysku twardym (lub innym urządzeniu pamięci masowej), które pozwalają na organizację danych, instalację systemu operacyjnego i zarządzanie przestrzenią dyskową.</p>
                <ol>
                    <li><span style={{fontWeight:'bold',fontSize:'1.2em'}}>Rodzaje partycji</span>
                        <ul>
                            <li><b>Podstawowa (Primary Partition)</b><br/>
                            Może zawierać system operacyjny lub dane. Windows pozwala na maksymalnie <u>cztery partycje podstawowe na jednym dysku</u>, chyba że używana jest tabela partycji GPT, a nie MBR.
                            </li>
                            <li><b>Rozszerzona (Extended Partition)</b><br/>
                            Jest to specjalny rodzaj partycji, która może zawierać wiele dysków logicznych. Służy do obejścia limitu czterech partycji podstawowych w tabeli MBR.
                            </li>
                            <li><b>Rozszerzona (Extended Partition)</b><br/>
                            Jest to specjalny rodzaj partycji, która może zawierać wiele dysków logicznych. Służy do obejścia limitu czterech partycji podstawowych w tabeli MBR.
                            </li>
                            <li><b>Dysk logiczny (Logical Drive)</b><br/>
                            To części w ramach partycji rozszerzonej, które mogą być używane jak oddzielne dyski do przechowywania danych.
                            </li>
                        </ul>
                    </li>
                    <li><span style={{fontWeight:'bold',fontSize:'1.2em'}}>Tabela partycji</span>
                        <ul>
                            <li><b>MBR (Master Boot Record)</b><br/>
                            Starszy typ tabeli partycji, obsługuje maksymalnie 4 partycje podstawowe i dyski o wielkości do 2 TiB.
                            </li>
                            <li><b>GPT (GUID Partition Table)</b><br/>
                            Nowoczesny standard, obsługuje nieograniczoną liczbę partycji (Windows pozwala na 128) i dyski większe niż 2 TiB. Wymaga UEFI w miejsce BIOS.
                            </li>
                        </ul>
                    </li>
                    <li><span style={{fontWeight:'bold',fontSize:'1.2em'}}>Rodzaje partycji w Windows</span>
                    <ul>
                        <li><b>Systemowa</b><br/>
                        Zawiera pliki rozruchowe systemu operacyjnego. Jest wymagana do uruchomienia systemu Windows (np. partycja EFI na dyskach GPT).
                        </li>
                        <li><b>Bootowalna (Windows)</b><br/>
                        Zawiera system operacyjny, zwykle oznaczana jako <Command>C:\</Command>.
                        </li>
                        <li><b>Odzyskiwania (Recovery Partition)</b><br/>
                        Zawiera narzędzia do przywracania systemu do stanu fabrycznego.
                        </li>
                        <li><b>Danych (Data Partition)</b><br/>
                        Używana do przechowywania plików użytkownika, np. dokumentów, zdjęć czy filmów.
                        </li>
                        <li><b>Rezerwowane przez system (System Reserved)</b><br/>
                        Tworzona automatycznie podczas instalacji Windows, zawiera pliki niezbędne do uruchomienia systemu (np. menedżer rozruchu).
                        </li>
                    </ul>
                    </li>
                    <li><span style={{fontWeight:'bold',fontSize:'1.2em'}}>Zarządzanie partycjami w Windows</span>
                    <ul>
                        <li><b>Narzędzie: Zarządzanie dyskami</b><br/>
                        (Otwórz przez <Command>diskmgmt.msc</Command> lub klikając prawym przyciskiem myszy na "Start" -&gt; Zarządzanie dyskami).
                        <ResultImage imgPath='partition-1.webm' video/>
                        </li>
                        <li><b>DiskPart</b><br/>
                        Narzędzie wiersza poleceń dla zaawansowanych użytkowników.
                        <ResultImage imgPath='partition-2.png'/>
                        </li>
                    </ul>
                    </li>
                    <li><span style={{fontWeight:'bold',fontSize:'1.2em'}}>Systemy plików</span>
                    <ul>
                        <li><b>NTFS (New Technology File System)</b><br/>
                        Domyślny system plików Windows, obsługuje uprawnienia, kompresję, szyfrowanie i duże pliki.
                        </li>
                        <li><b>FAT32</b><br/>
                        Starszy system plików, kompatybilny z wieloma urządzeniami, ale obsługuje pliki do 4 GiB i partycje do 32 GiB.
                        </li>
                        <li><b>exFAT</b><br/>
                        Używany na nośnikach przenośnych, łączy zalety FAT32 i NTFS.
                        </li>
                    </ul>
                    </li>
                    <li><span style={{fontWeight:'bold',fontSize:'1.2em'}}>Typowe scenariusze użycia partycji</span>
                    <ul>
                        <li><b>Rozdzielenie systemu i danych</b><br/>
                        Instalacja Windows na jednej partycji (np. <Command>C:</Command>) i przechowywanie danych na innej (np. <Command>D:</Command>).
                        </li>
                        <li><b>Dual Boot</b><br/>
                        Instalacja kilku systemów operacyjnych na osobnych partycjach.
                        </li>
                        <li><b>Kopia zapasowa</b><br/>
                        Wydzielenie partycji do przechowywania backupów. W tym przypadku zalecane są jednak osobne dyski, aby zminimalizować utratę danych przy awarii dysku.
                        </li>
                    </ul>
                    </li>
                </ol>
            </Collapsible>

            <Collapsible title="Zarządzanie dyskami (diskmgmt.msc)" nowe admin>
            <p>Przystawka <Command>diskmgmt.msc</Command> (Zarządzanie dyskami) to narzędzie wbudowane w system Windows, służące do zarządzania dyskami i partycjami w intuicyjnym graficznym interfejsie. Umożliwia wykonywanie podstawowych i zaawansowanych operacji na dyskach twardych, SSD, pamięciach USB, a także na zewnętrznych urządzeniach magazynujących.</p>
            <ol>
                <li><span style={{fontWeight:'bold',fontSize:'1.2em'}}>Jak otworzyć przystawkę Zarządzanie dyskami?</span>
                <ul>
                    <li>Naciśnij <KeyShortcut windows></KeyShortcut> + <KeyShortcut>R</KeyShortcut>, wpisz <Command>diskmgmt.msc</Command> i naciśnij <Command>Enter</Command>.</li>
                    <li>Kliknij prawym przyciskiem myszy na <KeyShortcut windows></KeyShortcut>(Start), wybierz Zarządzanie dyskami.</li>
                    <li>W menu <b>Start</b>, wyszukaj „Zarządzanie dyskami”.</li>
                    <li>W konsoli MMC dodaj przystawkę „Zarządzanie dyskami” i wybierz Komputer lokalny.</li>
                </ul>
                </li>
                <li><span style={{fontWeight:'bold',fontSize:'1.2em'}}>Główne funkcje Zarządzania dyskami</span>
                <ul>
                    <li><b>Tworzenie partycji</b><br/>
                    Pozwala na utworzenie nowych woluminów na nieprzydzielonym miejscu na dysku.
                    </li>
                    <li><b>Formatowanie partycji</b><br/>
                    Przygotowanie nowej partycji do użytku, poprzez przypisanie systemu plików (NTFS, exFAT, FAT32).
                    </li>
                    <li><b>Zmiana liter dysków i ścieżek</b><br/>
                    Możliwość zmiany oznaczenia literowego partycji (np. z <Command>D:</Command> na <Command>E:</Command>).
                    </li>
                    <li><b>Rozszerzanie partycji</b><br/>
                    Dodanie nieprzydzielonego miejsca do istniejącej partycji.
                    </li>
                    <li><b>Zmniejszanie partycji</b><br/>
                    Zwolnienie części miejsca na istniejącej partycji, tworząc przestrzeń na nowe.
                    </li>
                    <li><b>Usuwanie partycji</b><br/>
                    Usuwa wybraną partycję i zamienia jej miejsce na „Nieprzydzielone”.
                    </li>
                    <li><b>Konwersja dysków</b><br/>
                        <ul>
                            <li>Z dysku podstawowego na dynamiczny i odwrotnie.</li>
                            <li>Z tabeli MBR na GPT (tylko dla pustych dysków).</li>
                        </ul>
                    </li>
                    <li><b>Tworzenie dysków logicznych</b><br/>
                    W ramach partycji rozszerzonej.
                    </li>
                </ul>
                </li>
                <li><span style={{fontWeight:'bold',fontSize:'1.2em'}}>Widok interfejsu</span>
                <p>Przystawka przedstawia dwie sekcje:</p>
                <ResultImage imgPath='partition-3.png'/>
                <ul>
                    <li style={{color:'red'}}><b>Lista dysków</b><br/>
                    <span style={{color:'#ffffff'}}>Pokazuje szczegóły każdego dysku: numer, typ, rozmiar, woluminy, stan i system plików.</span>
                    </li>
                    <li style={{color:'green'}}><b>Graficzne przedstawienie dysków</b><br/>
                    <span style={{color:'#ffffff'}}>Każdy dysk jest pokazany w formie paska, z podziałem na partycje, ich rozmiary i typ (np. podstawowy, rozszerzony, dynamiczny).</span>
                    </li>
                </ul>
                </li>
                <li><span style={{fontWeight:'bold',fontSize:'1.2em'}}>Stan dysków i partycji</span>
                    <ul>
                    <li><b>Online</b>: Dysk jest w pełni funkcjonalny.</li>
                    <li><b>Offline</b>: Dysk jest wyłączony (można go włączyć ręcznie).</li>
                    <li><b>Nieprzydzielone</b>: Miejsce na dysku nieprzypisane do żadnej partycji.</li>
                    <li><b>Zarezerwowane przez system</b>: Obszar zarezerwowany przez Windows, np. dla partycji EFI.</li>
                    <li><b>Brak przydziału liter</b>: Partycja działa, ale nie jest przypisana do litery.</li>
                    </ul>  
                </li>
                <li><span style={{fontWeight:'bold',fontSize:'1.2em'}}>Przykład zmniejszenia partycji o <b>10 GiB</b></span>
                    <ResultImage imgPath='partition-4.webm' video />
                </li>
            </ol>
            </Collapsible>

            <Collapsible title='Przydatne "haki" w CMD'>
                <p>Aby ułatwić poruszanie się po CMD, dobrzi ludzie z Microsoftu zapożyczyli pewnie z systemów UNIX-owych kilka przydatnych skrótów klawiszowych.</p>
                <ul>
                    <li>Klawisze <KeyShortcut up/> oraz <KeyShortcut down/> - pozwalają nam na poruszanie się w historii użytych poleceń. Klawisz <KeyShortcut up/> cofnie nas do ostatniego polecenia, jakie użyliśmy. Więcej naciśnieć tego klawisza cofnie nas jeszcze dalej. Klawisz <KeyShortcut down/> pozwoli nam wrócić do nowszych poleceń jakie używaliśmy, jeżeli zabłączimy zbyt daleko w historii.<ResultImage imgPath='cmd-klawisze-2.webm' video/></li>
                    <li>Klawisz <KeyShortcut>TAB</KeyShortcut> uzupełni za nas nazwę pliku lub folderu, wystarczy podać początek nazwy i nacisnąć <KeyShortcut>TAB</KeyShortcut>. Jeżeli wiele plików lub folderów zaczyna się tak samo to wielokrotne naciśnięcie klawisza <KeyShortcut>TAB</KeyShortcut> spowoduje rotację między tymi nazwami.
                    <ResultImage imgPath='cmd-klawisze-1.webm' video/></li>
                    <li>Strzałkami <KeyShortcut>&lt;</KeyShortcut> oraz <KeyShortcut>&gt;</KeyShortcut> możemy się przemieszczać karetką (kursorem) w poleceniu w celu np. poprawy literówki.<ResultImage imgPath='cmd-klawisze-3.webm' video/></li>
                    <li>Nie podoba nam się domyślny kształt kursora w cmd - ta gruba pozioma kreska, która czasami bywa nieczytelna? Możesz to zmienić w ustawieniach cmd! Wystarczy że naciśniesz Prawym Przyciskiem Myszy na pasek tytułowy &gt; Z menu kontekstowego wybierzesz Właściwości (zmienia tylko dla bieżącego okna konsoli) lub Domyslne (ustawia "na stałe").<ResultImage imgPath='cmd-klawisze-4.webp'/>
                    <p>W polu "Kształt kursora" domyslnie jest wybrana opcja "Użyj starego stylu", możemy zmienić na np. "Kreska pionowa", która jest najbardziej popularna!</p>
                    <p style={{color:'red'}}>Jeżeli wybrałeś odpcję "Domyślne" z menu kontekstowego to efekt zobaczysz dopiero po ponownym uruchomieniu cmd!</p></li>
                    <li>Kombinacją klawiszy <KeyShortcut>Ctrl</KeyShortcut> + <KeyShortcut>C</KeyShortcut> możemy przerwać działanie jakiegoś polecenia. Na przykład jak odpalimy polecenie <Command>tree</Command> w katalogu C:\Windows:<ResultImage imgPath='cmd-klawisze-5.webm' video/>
                    <p>Po przerwaniu polecenia w cmd pokaże nam się symbol <Command>^C</Command>, który informuje o przrerwaniu.</p></li>
                </ul>
            </Collapsible>

            <Collapsible title='Polecenie rename - zmiana nazwy pliku'>
                <p>Te polecenie pozwala nam zmienić nazwę pliku poprzez wiersz poleceń. Składnia wygląda następująco:</p>
                <SyntaxHighlighter language='bash' style={vs2015}>
                    rename nazwa_pliku nowa_nazwa
                </SyntaxHighlighter>
                <p>W <Command>nazwa_pliku</Command> podajemy albo nazwę pliku, jeżeli plik jest w tym samym katalogu co my lub ścieżkę do pliku. W <Command>nowa_nazwa</Command> podajemy po prostu nową nazwę <b>z rozszerzeniem</b>!</p>
            </Collapsible>

            <Collapsible title="Polecenie copy - kopiowanie plików">
            <p>Polecenie <Command>copy</Command> w wierszu poleceń Windows służy do kopiowania plików z jednego miejsca do drugiego.</p>
            <ol>
                <li><b>Podstawowa składnia</b>
                    <SyntaxHighlighter language='bash' style={vs2015}>
                        copy źródło cel
                    </SyntaxHighlighter>
                    <ul>
                        <li><Command>źródło</Command>: lokalizacja pliku lub plików, które chcesz skopiować.</li>
                        <li><Command>cel</Command>: miejsce docelowe, do którego chcesz skopiować plik lub pliki.</li>
                    </ul>
                    <p>Na przykład</p>
                    <SyntaxHighlighter language='bash' style={vs2015}>
                        copy C:\Dokumenty\raport.txt D:\Kopia
                    </SyntaxHighlighter>
                    <p className='description'>To polecenie skopiuje plik <Command>raport.txt</Command> z folderu <Command>C:\Dokumenty</Command> do folderu <Command>D:\Kopia</Command>.</p>
                </li>
                <li><b>Kopiowanie Plików do Tego Samego Katalogu z Nową Nazwą</b>
                <p>Możesz również użyć <Command>copy</Command>, aby skopiować plik w tej samej lokalizacji, nadając mu nową nazwę:</p>
                <SyntaxHighlighter language='bash' style={vs2015}>
                    copy dokument.txt kopia_dokumentu.txt
                </SyntaxHighlighter>
                </li>
                <li><b>Kopiowanie Wielu Plików</b>
                <p>Aby skopiować wszystkie pliki z określonym rozszerzeniem, możesz użyć symbolu wieloznacznego <Command>*</Command>. Na przykład:</p>
                <SyntaxHighlighter language='bash' style={vs2015}>
                    copy C:\Dokumenty\*.txt D:\Kopia
                </SyntaxHighlighter>
                <p className='description'>To polecenie skopiuje wszystkie pliki <Command>.txt</Command> z folderu <Command>C:\Dokumenty</Command> do folderu <Command>D:\Kopia</Command>.</p>
                </li>
            </ol>
            </Collapsible>

            <Collapsible title="Polecenie cls - czyszczenie ekranu konsoli">
            <p>Te polecenie czyści ekran konsoli i nic więcej ❤️</p>
            <ResultImage imgPath='cls-1.webm' video />
            </Collapsible>

            <Collapsible title="Polecenie mkdir - tworzenie katalogu">
                <p>Polecenie <Command>mkdir</Command> lub <Command>md</Command> w systemie Windows służy do tworzenia nowych katalogów.</p>
                <SyntaxHighlighter language='bash' style={vs2015}>
                    mkdir katalog1
                </SyntaxHighlighter>         
                <p className='description'>Te polecenie utworzy nowy katalog/folder w obecnym folderze, w którym się znajdujemy.</p>
                <SyntaxHighlighter language='bash' style={vs2015}>
                    mkdir C:\Users\admin\Desktop\Pliki
                </SyntaxHighlighter>
                <p className='description'>Te polecenie utworzy nowy katalog/folder o nazwie <Command>Pliki</Command> w miejscu, które określiliśmy podając ściezkę - na pulpicie użytkownika admin.</p>
                <SyntaxHighlighter language='bash' style={vs2015}>
                    mkdir kat1\kat2\kat3\kat4
                </SyntaxHighlighter>
                <p className='description'>Te polecenie utworzy szereg katalogów w sobie, działa tak samo jak te polecenia:</p>
                <SyntaxHighlighter language='bash' style={vs2015}>
                    {`mkdir kat1\ncd kat1\nmkdir kat2\ncd kat2\nmkdir kat3\ncd kat3\nmkdir kat4`}
                </SyntaxHighlighter>
                <p>Jeżeli chcemy utworzyć katalog z nazwą, która zawiera spacje to nazwę podajemy w cudzysłowie:</p>
                <SyntaxHighlighter language='bash' style={vs2015}>
                    {`mkdir "Nazwa katalogu ze spacjami"`}
                </SyntaxHighlighter>
            </Collapsible>

            <Collapsible title="Polecenie rd - usuwanie katalogów">
                <p>Polecenie <Command>rd</Command> lub <Command>rmdir</Command> w systemie Windows służy do usuwania katalogów.</p>
                <SyntaxHighlighter language='bash' style={vs2015}>
                    rd [/S] [/Q] &lt;ścieżka&gt;
                </SyntaxHighlighter>         
                Polecenie przyjmuje jeden argument - ścieżkę oraz ma 2 opcje, które podajemy <b>przed ściezką</b>:
                <ul>
                    <li><Command>/S</Command> - usuwa cały katalog z jego zawartością - usuwa drzewo katalogów.</li>
                    <li><Command>/Q</Command> - tryb cichy - nie prosi nas o potwierdzenie usunięcia</li>
                </ul>
                <SyntaxHighlighter language='bash' style={vs2015}>
                    rd /S /Q "Ważne dokumenty"
                </SyntaxHighlighter> 
                <p className='description'>Te polecenie usunie, bez pytania się, cały katalog wraz z zawartością.</p>
            </Collapsible>

            <Collapsible title="Polecenie del - usuwanie plików">
                <p>Polecenie <Command>del</Command> w systemie Windows służy do usuwania plików.</p>
                <SyntaxHighlighter language='bash' style={vs2015}>
{`C:\\Users\\admin\\Desktop>del /?
Deletes one or more files.

DEL [/P] [/F] [/S] [/Q] [/A[[:]attributes]] names
ERASE [/P] [/F] [/S] [/Q] [/A[[:]attributes]] names

  names         Specifies a list of one or more files or directories.
                Wildcards may be used to delete multiple files. If a
                directory is specified, all files within the directory
                will be deleted.

  /P            Prompts for confirmation before deleting each file.
  /F            Force deleting of read-only files.
  /S            Delete specified files from all subdirectories.
  /Q            Quiet mode, do not ask if ok to delete on global wildcard
  /A            Selects files to delete based on attributes
  attributes    R  Read-only files            S  System files
                H  Hidden files               A  Files ready for archiving
                I  Not content indexed Files  L  Reparse Points
                O  Offline files              -  Prefix meaning not

If Command Extensions are enabled DEL and ERASE change as follows:

The display semantics of the /S switch are reversed in that it shows
you only the files that are deleted, not the ones it could not find.
`}
                </SyntaxHighlighter>         
                Polecenie przyjmuje jeden argument - ścieżkę do pliku lub nazwę pliku w bieżącym katalogu oraz ma różne opcje, które podajemy <b>przed ściezką</b>:
                <ul>
                    <li><Command>/P</Command> - pyta się nas o usunięcie każdego pliku (przydatne, gdy używamy znaków globalnych przy podaniu nazwy)</li>
                    <li><Command>/F</Command> - wymusi usuwanie plików, które mają atrybut "tylko do odczytu"</li>
                    <li><Command>/S</Command> - usunie wszystkie pliki o podanej nazwie, również w podkatalogach</li>
                    <li><Command>/Q</Command> - tryb cichy - nie pyta się o usunięcie podczas używania znaków globalnych (symboli wieloznacznych)</li>
                    <li><Command>/A</Command> - pozwala nam na usunięcie plików o danym atrybucie, <b>zasada działania oraz składnia tak samo jak w poleceniu dir /A</b></li>
                </ul>
                <h3>Przykład</h3>
                <SyntaxHighlighter language='bash' style={vs2015}>
                    del /F /Q /A:-A *.pdf
                </SyntaxHighlighter> 
                <p className='description'>Te polecenie usunie wszystkie pliki <Command>.pdf</Command>, które <b>nie mają</b> atrybutu "Archiwalny". Wymusi również usuwanie plików tylko do odczytu oraz nie będzie nas prosić o potwierdzenie usunięcia.</p>
            </Collapsible>

            <Collapsible title="Polecenie type - wyświetlanie zawartości pliku">
                <p>Polecenie <Command>type</Command> w systemie Windows służy do wyświetlania zawartości plików tekstowych w terminalu wiersza poleceń. Jedynym parameterm dla tego pliku jest nazwa pliku do wyświetlenie lub pełna ścieżka do pliku.</p>
                <SyntaxHighlighter language='bash' style={vs2015}>
                    type plik1.txt
                </SyntaxHighlighter>         
                <p className='description'>Te polecenie wyświetli zawartość pliku o nazwie <Command>plik1.txt</Command> w bieżącym katalogu.</p>
            </Collapsible>

            <Collapsible title="Polecenie echo - wyświetlanie tekstu w konsoli">
                <p>Polecenie <Command>echo</Command> w systemie Windows jest używane głównie do wyświetlania tekstu na ekranie</p>
                <ol>
                    <li><b>Wyświetlanie tekstu</b> - Najczęściej używa się <Command>echo</Command>, aby wyświetlić tekst na konsoli. Składnia jest prosta:
                    <SyntaxHighlighter language='bash' style={vs2015}>
                        echo Tekst do wyświetlenia
                    </SyntaxHighlighter>
                    Jak widać nie potrzebujemy nawet tekstu w cudzysłowie, po słowie <Command>echo</Command> Wiersz poleceń interpretuje wszystko jako tekst do wyświetlenia.
                    </li>
                </ol>
                <ResultImage imgPath='echo-1.webp' desc='Przykład polecenia echo'/>
            </Collapsible>

            <Collapsible title="Znaki przekierowywania w CMD">
                <p>W wierszu poleceń CMD w systemie Windows, znaki <Command>&gt;</Command> i <Command>&gt;&gt;</Command> służą do przekierowywania wyników poleceń do plików. Różnią się one sposobem, w jaki zapisują dane w pliku:</p>
                <ol>
                    <li><Command>&gt;</Command> - Służy do nadpisywania pliku. Jeśli plik o podanej nazwie już istnieje, jego zawartość zostanie zastąpiona nowymi danymi. Na przykład:
                    <SyntaxHighlighter language='bash' style={vs2015}>
                        echo Hello World &gt; h_world.txt
                    </SyntaxHighlighter>
                    <p>To polecenie utworzy plik h_world.txt (lub nadpisze, jeśli już istnieje) i zapisze w nim tekst "Hello World".</p>
                    </li>
                    <li><Command>&gt;&gt;</Command> - Służy do dopisywania do pliku. Jeśli plik już istnieje, nowe dane zostaną dodane na końcu istniejącej zawartości, a jeśli nie istnieje, zostanie utworzony nowy plik. Na przykład:
                    <SyntaxHighlighter language='bash' style={vs2015}>
                        echo Kolejna linia &gt;&gt; h_world.txt
                    </SyntaxHighlighter>
                    <p>To polecenie dopisze tekst "Another line" na końcu pliku output.txt bez usuwania istniejącej zawartości.</p>
                    </li>
                </ol>
                <h3>Przykład</h3>
                <ResultImage imgPath='znakprzek.webm' video/>
                <p style={{color: 'red'}}>Warto pamiętać, że te znaki działają <b style={{color: 'red'}}>z każdym poleceniem</b>. W taki sposób możemy przekierowywać wynik jakiegoś polecenia do pliku, np.: <Command>date /t &gt; data.txt</Command> przekieruje wynik działania polecenia "date" (obecna data) do pliku zamiast ekranu konsoli.</p>
                <p>Te operatory są przydatne przy tworzeniu logów, zapisywaniu wyników poleceń lub tworzeniu skryptów, które zbierają dane do jednego pliku.</p>
            </Collapsible>

            <Collapsible title="Polecenie title - tytuł okna">
            <p>Polecenie <Command>title</Command> słuzy do zmiany nazwy okna Wiersza poleceń.</p>
            <p>Poleceniem <Command>title /?</Command> możemy zobaczyć pomoc na temat tego polecenia.</p>
            <SyntaxHighlighter language='text' style={vs2015}>
                {`C:\\Windows\\system32>title /?
Sets the window title for the command prompt window.

TITLE [string]

  string       Specifies the title for the command prompt window.`}
            </SyntaxHighlighter>
            <p className='description'>Te polecenie przyjmuje tylko jeden argument - <Command>[string]</Command>, do którego wprowadzamy tekst jaki ma być widoczny na pasku tytułowym Wiersza poleceń.</p>
            <h3>Przykład polecenia <Command>title</Command></h3>
            <SyntaxHighlighter language='text' style={vs2015}>
                title Przykład polecenia title
            </SyntaxHighlighter>
            <ResultImage imgPath='title-1.webp' desc='Jak widać, pasek tytułowy okna zmienił sie na tekst wpisany przez polecenie.'/>
            </Collapsible>

            <Collapsible title="Polecenie label - zmiana etykiety dysku oraz vol - wyświetlenie etykiety dysku" admin>
            <p>Polecenie <Command>label</Command> słuzy do zmiany etykiety dysku, która jest wyświetlana między innymi w Eksploratorze plików.</p>
            <p>Poleceniem <Command>label /?</Command> możemy zobaczyć pomoc na temat tego polecenia.</p>
            <SyntaxHighlighter language='text' style={vs2015}>
                {`C:\\Windows\\system32>label /?
Creates, changes, or deletes the volume label of a disk.

LABEL [drive:][label]
LABEL [/MP] [volume] [label]

  drive:          Specifies the drive letter of a drive.
  label           Specifies the label of the volume.
  /MP             Specifies that the volume should be treated as a
                  mount point or volume name.
  volume          Specifies the drive letter (followed by a colon),
                  mount point, or volume name.  If volume name is specified,
                  the /MP flag is unnecessary.`}
            </SyntaxHighlighter>
            <p className='description'>Te polecenie przyjmuje zazwyczaj 2 argumenty: <Command>[drive:]</Command> - litera dysku, dla którego chcemy zmienić etykietę. Musi być dwukropek na końcu, np. <Command>C:</Command>; <Command>[label]</Command> - tekst nowej etykiety.</p>
            <h3>Przykład polecenia <Command>label</Command></h3>
            <SyntaxHighlighter language='text' style={vs2015}>
                label C: Dysk systemowy
            </SyntaxHighlighter>
            <ResultImage imgPath='label-1.webp' desc='Etykieta dysku została zmieniona na podaną w poleceniu.'/>
            <h3>Polecenie <Command>vol</Command></h3>
            Tym poleceniem możemy wyświetlić etykietę dla bieżącego dysku:
            <ResultImage imgPath='label-2.webp' desc='Jak widać te polecenie pokazuje nam etykietę dysku oraz numer seryjny woluminu.'/>
            </Collapsible>

            <Collapsible title="Polecenie dir - wyświetlanie zawartości katalogu">
            <p>Polecenie <Command>dir</Command> słuzy do wyświetlania zawartości katalogu w konsoli Wiersza poleceń.</p>
            <p>Poleceniem <Command>dir /?</Command> możemy zobaczyć pomoc na temat tego polecenia.</p>
            <SyntaxHighlighter language='text' style={vs2015}>
                {`C:\Windows\system32>dir /?
Displays a list of files and subdirectories in a directory.

DIR [drive:][path][filename] [/A[[:]attributes]] [/B] [/C] [/D] [/L] [/N]
  [/O[[:]sortorder]] [/P] [/Q] [/R] [/S] [/T[[:]timefield]] [/W] [/X] [/4]

  [drive:][path][filename]
              Specifies drive, directory, and/or files to list.

  /A          Displays files with specified attributes.
  attributes   D  Directories                R  Read-only files
               H  Hidden files               A  Files ready for archiving
               S  System files               I  Not content indexed files
               L  Reparse Points             O  Offline files
               -  Prefix meaning not
  /B          Uses bare format (no heading information or summary).
  /C          Display the thousand separator in file sizes.  This is the
              default.  Use /-C to disable display of separator.
  /D          Same as wide but files are list sorted by column.
  /L          Uses lowercase.
  /N          New long list format where filenames are on the far right.
  /O          List by files in sorted order.
  sortorder    N  By name (alphabetic)       S  By size (smallest first)
               E  By extension (alphabetic)  D  By date/time (oldest first)
               G  Group directories first    -  Prefix to reverse order
  /P          Pauses after each screenful of information.
  /Q          Display the owner of the file.
  /R          Display alternate data streams of the file.
  /S          Displays files in specified directory and all subdirectories.
  /T          Controls which time field displayed or used for sorting
  timefield   C  Creation
              A  Last Access
              W  Last Written
  /W          Uses wide list format.
  /X          This displays the short names generated for non-8dot3 file
              names.  The format is that of /N with the short name inserted
              before the long name. If no short name is present, blanks are
              displayed in its place.
  /4          Displays four-digit years

Switches may be preset in the DIRCMD environment variable.  Override
preset switches by prefixing any switch with - (hyphen)--for example, /-W.`}
            </SyntaxHighlighter>
            <p className='description'>Te polecenie zawiera wiele różnych opcji, każda jest całkiem czytelnie opisana w pomocy w języku angielskim.</p>
            <h3>Przykłady polecenia <Command>dir</Command></h3>
            <p>Jeżeli chcemy wyświetlić zawartość bieżącego katalogu, wystarczy napisać:</p>
            <SyntaxHighlighter language='text' style={vs2015}>
                dir
            </SyntaxHighlighter>
            <SyntaxHighlighter language='text' style={vs2015}>
                {`C:\\Users>dir
 Volume in drive C is Dysk systemowy
 Volume Serial Number is E669-40D6

 Directory of C:\\Users

16.09.2024  23:02    <DIR>          .
16.09.2024  23:02    <DIR>          ..
16.09.2024  22:50    <DIR>          admin
25.06.2024  07:19    <DIR>          Public
16.09.2024  23:05    <DIR>          uczen
               0 File(s)              0 bytes
               5 Dir(s)  33 733 144 576 bytes free

C:\Users>`}
            </SyntaxHighlighter>
                <p>Znajduję sie w katalogu <Command>Users</Command> na dysku <Command>C:</Command>, co pokazuje ścieżka przed poleceniem <Command>C:\Users&gt;</Command>. Domyslnie polecenie dir wyświetla etykietę dysku, numer seryjny woluminu, informację dla jakiego katalogu wyświetla zawartość, pliki z datą i godziną utworzenia pliku lub katalogu. Napis <Command>&lt;DIR&gt;</Command> informuje że element w tym wierszu jest katalogiem, na końcu po prawej stronie znajduje się nazwa pliku lub katalogu. Można również zobaczyć katalogi <Command>.</Command> oraz <Command>..</Command>, które są ścieżkami relatywnymi, które domyslnie wyświetla system Windows. Pojedyncza kropka to obecny katalog, a podwójna kropka to katalog nadrzędny - jeden wstecz, w tym przypadku to sam dysk <Command>C:</Command>.</p>
                <p>Ostatnie 2 linijki to informacje ile jest plików oraz ile razem te pliki w tym katalogu zajmują miejsca (używana jest jednostka bajtów) oraz linijka z informacją o ilości podkatalogów w obecnym katalogu oraz wolnym miejscu na dysku, również w bajtach.</p>
                <p>Jeżeli chciałbym zobaczyć zawartość katalogu w inne ścieżce np. <Command>C:\Users\admin</Command> bez przechodzenia między katalogami, mogę użyć tego polecenia:</p>
                <SyntaxHighlighter language='text' style={vs2015}>
                    dir C:\Users\admin
                </SyntaxHighlighter>
                <p>Dalej zostanę w obecnym katalogu, w którym jestem, ale podejrzę zawartość innego katalogu, który może byc nawet na innym dysku.</p>
                <h3>Opcje polecenia <Command>dir</Command></h3>
                <ul>
                    <li><Command>/B</Command> - ta opcja wyświetla tylko nazwy plików bez zbędnych informacji.
                    <ResultImage imgPath='dir-1.webp' desc='Po lewej wynik polecenia "dir", po prawej wynik polecenia "dir /B".'/></li>
                    <li><Command>/A</Command> - ta opcja pozwala nam na wyświetlanie plików tylko z określonym atrybutem. Atrybut podajemy po dwukropku bez spacji!
                    <ResultImage imgPath='dir-2.webp' desc='Pierwsze polecenie pokazuje wszystkie pliki na pulpicie użytkownika admin z atrybutem Systemowy, drugie polecenie pokazuje wszystkie pliki na pulpicie użytkownika admin z atrybutem Archiwalny.'/>
                    <p>Jeżeli literę atrybutu poprzedzimi znakiem <Command>-</Command> to będzie to oznaczać, że polecenie <Command>dir</Command> ma wyświetlić wszystkie pliki, które <b>nie posiadają</b> wymienionego atrybutu!</p></li>
                </ul>
                <p>Reszta Soon&trade;</p>
            </Collapsible>

            <Collapsible title="Polecenie tree - drzewo struktury katalogów">
            <p>Polecenie <Command>tree</Command> słuzy do wyświetlania drzewa katalogów w konsoli Wiersza poleceń.</p>
            <ResultImage imgPath='tree-1.webp'></ResultImage>
            <p className='description'>Podobnie jak <Command>dir</Command>, domyślnie <Command>tree</Command> wyświetla drzewo katalogów dla bieżącego folderu. Możemy również podać inną ścieżkę, bez przechodzenia do innego katalogu np.:</p>
            <SyntaxHighlighter language='text' style={vs2015}>
                tree C:\Windows\system32
            </SyntaxHighlighter>
            <p>Te polecenie posiada tylko 2 opcje:</p>
            <ul>
                <li><Command>/F</Command> - pokazuje również pliki w folderach,</li>
                <li><Command>/A</Command> - zamienia gałęzie (linie z lewej strony) na znaki ASCII.</li>
            </ul>
            </Collapsible>

            <Collapsible title="Polecenie cd - poruszanie się między katalogami">
            <p>Poleceniem <Command>cd</Command> możemy przemieszczać się między katalogami z Wiersza poleceń. Mamy 2 możliwe opcje podawanie ścieżek na przemieszczanie się:</p>
            <ol>
                <li>Ścieżki absolutne</li>
                <p>Ścieżki absolutne, inaczej pełne ścieżki wymagają od nas podania pełnej ścieżki włącznie z dyskiem np.:</p>
                <SyntaxHighlighter language='bash' style={vs2015}>
                    cd C:\Users\admin\Desktop
                </SyntaxHighlighter>
                <p className='description'>To polecenie przeniesie nas na Pulpit użytkownika <b>admin</b>.</p>
                <p>Zaletą ścieżek absolutnych jest to, że niezależnie gdzie obecnie się znajdujemy oraz jeżeli podamy prawidłową ścieżkę to polecenie <Command>cd</Command> przeniesie nas bez problemu do wybranego katalogu.</p>
                <li>Ścieżki relatywne</li>
                <p>Ścieżki relatywne pozwalają nam na porusznaiu się w okół katalogu, w którym obecnie się znajdujemy. Rozróżniamy 2 rodzaje ścieżek relatywnych:</p>
                <ol>
                    <li><Command>.</Command> - bieżący katalog</li>
                    <p>Dzięki temu możemy przejść w głąb obecnego katalogu np.:</p>
                    <SyntaxHighlighter language='bash' style={vs2015}>
                        {`C:\\Users>cd .\\admin\\Desktop
C:\\User\\admin\\Desktop>`}
                    </SyntaxHighlighter>
                    <p className='description'>Tym poleceniem przeniesiemy się z folderu <Command>C:\Users</Command> do folderu <Command>C:\Users\admin\Desktop</Command> bez podawania pełnej ścieżki!</p>
                    <li><Command>..</Command> - katalog nadrzędny</li>
                    <p>Katalog nadrzędny lub katalog wstecz pozwala nam na cofnięcie się o jeden katalog w obecnej ścieżce np.:</p>
                    <SyntaxHighlighter language='bash' style={vs2015}>
                        {`C:\\Users\\admin\\Desktop>cd ..\\..
C:\\Users>`}
                    </SyntaxHighlighter>
                    <p className='description'>Tym poleceniem cofniemy się o 2 foldery, z folderu <Command>C:\Users\admin\Desktop</Command> do folderu <Command>C:\Users</Command></p>
                </ol>
            </ol>
            </Collapsible>

            <Collapsible title="Polecenie date oraz time - wyświetlenie lub zmiana daty i czasu">
            <p>Obydwa polecenia mają taką samą składnię. Jeżeli do cmd wpiszemy <Command>time</Command> lub <Command>date</Command> to system poprosi nas o podanie nowego czasu lub daty. Jeżeli podamy opcję <Command>/t</Command> to system wyświetli tylko obecny czas lub obecną datę.</p>
            <ResultImage imgPath='datetime-1.webp'/>
            
            </Collapsible>

            <Collapsible title="Znaki globalne / wieloznaczne">
                <p>
                W systemie Windows znaki globalne <Command>*</Command> (gwiazdka) i <Command>?</Command> (znak zapytania) są używane głównie jako znaki wieloznaczne (wildcards) w wyszukiwaniu i operacjach na plikach, zwłaszcza w poleceniach wiersza poleceń (CMD) oraz w zapytaniach systemowych.
                </p>
                <h2>Znaczenie:</h2>
                <ol>
                    <li><b><Command>*</Command> (gwiazdka):</b>
                        <ul>
                            <li>
                                Reprezentuje dowolny ciąg znaków (w tym pusty ciąg).
                            </li>
                            <li>
                                Używany do wyszukiwania plików, których nazwy mogą mieć różną długość lub zawierać dowolny zestaw znaków.
                            </li>
                            <li>
                                Przykład:

                                <ul>
                                    <li>
                                        Wyszukanie wszystkich plików z rozszerzeniem <Command>.txt</Command>: <Command>*.txt</Command> – znajdzie pliki o nazwach typu <Command>dokument.txt</Command>, <Command>notatka.txt</Command> itd.
                                    </li>
                                    <li>
                                        <Command>dokument*</Command> – znajdzie pliki takie jak <Command>dokument1.txt</Command>, <Command>dokument_final.docx</Command>, itd.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <b><Command>?</Command> (znak zapytania):</b>
                        <ul>
                            <li>
                                Reprezentuje <b>dokładnie jeden znak</b>.
                            </li>
                            <li>
                                Używany, gdy chcesz znaleźć pliki z określoną liczbą znaków w nazwie, gdzie niektóre znaki są zmienne.
                            </li>
                            <li>
                                Przykład:
                                <ul>
                                    <li>
                                    <Command>plik?.txt</Command> – znajdzie pliki takie jak <Command>plik1.txt</Command>, <Command>plik2.txt</Command>, ale nie <Command>plik10.txt</Command> (bo zawiera dwa znaki zamiast jednego przed rozszerzeniem).
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ol>
                <h2>Przykłady użycia:</h2>
                <ul>
                    <li>
                        W wierszu poleceń: <Command>dir *.docx</Command> – wyświetli wszystkie pliki z rozszerzeniem <Command>.docx</Command> w bieżącym folderze.
                    </li>
                    <li>
                        W eksploratorze plików: można wyszukiwać pliki za pomocą tych znaków w polu wyszukiwania, np. <Command>raport?.xlsx</Command> (znajdzie pliki takie jak <Command>raport1.xlsx</Command> czy <Command>raport2.xlsx</Command>).
                    </li>
                </ul>
                <p>Są to bardzo przydatne narzędzia przy pracy z dużą liczbą plików lub podczas automatyzacji zadań.</p>
            </Collapsible>

            <Collapsible title="Atrybuty plików oraz polecenie attrib">
            <p>Atrybuty plików to specjalne właściwości, które można przypisać plikom i folderom w systemie operacyjnym, aby kontrolować ich zachowanie. Te właściwości są jak „tagi”, które definiują, jak system operacyjny powinien traktować dany plik lub folder, na przykład, czy powinien być widoczny, edytowalny lub traktowany jako plik systemowy.</p>
            <h3>Najczęściej spotykane atrybuty plików w systemie Windows:</h3>
            <ol>
                <li><b>Read-only (Tylko do odczytu) – <Command>R</Command></b><br/>Oznacza, że plik nie może być modyfikowany, jedynie odczytywany. Użytkownik lub program może otworzyć taki plik, ale nie może go edytować, zapisywać ani usuwać bez zmiany tego atrybutu.</li>
                <li><b>Hidden (Ukryty) – <Command>H</Command></b><br/>Plik lub folder z tym atrybutem nie jest widoczny w eksploratorze plików, chyba że w opcjach folderu zaznaczono, że mają być wyświetlane pliki ukryte. Jest to przydatne, aby ukrywać przed użytkownikami pliki, których nie powinni modyfikować.</li>
                <li><b>System (Systemowy) – <Command>S</Command></b><br/>Oznacza, że plik jest ważny dla działania systemu operacyjnego i nie powinien być zmieniany ani usuwany. Jest to zazwyczaj stosowane do plików krytycznych dla systemu Windows.</li>
                <li><b>Archive (Archiwalny) – <Command>A</Command></b><br/>Ten atrybut wskazuje, że plik został zmieniony od ostatniego utworzenia kopii zapasowej. Programy do tworzenia kopii zapasowych mogą korzystać z tego atrybutu, aby wiedzieć, które pliki wymagają ponownego zapisania w kopii.</li>
            </ol>
            <h3>Jakie jest praktyczne zastosowanie atrybutów?</h3>
            <p>
            Atrybuty pozwalają użytkownikowi i systemowi operacyjnemu kontrolować, które pliki mogą być modyfikowane, kopiowane lub wyświetlane. Na przykład:
            </p>
            <ul>
                <li>Pliki systemowe i ukryte są zabezpieczone przed przypadkowym usunięciem lub modyfikacją.</li>
                <li>Atrybut "tylko do odczytu" jest używany w przypadku dokumentów, które nie powinny być edytowane, na przykład w wersji finalnej.</li>
                <li>Atrybut archiwalny ułatwia systemom do backupu zidentyfikowanie plików, które zostały zmodyfikowane.</li>
            </ul>
            <p>Atrybuty są przechowywane w systemie plików i mogą być modyfikowane za pomocą odpowiednich narzędzi systemowych, takich jak polecenie <Command>attrib</Command>.</p>
            <h2>Polecenie <Command>attrib</Command></h2>
            <p>
            Polecenie attrib w systemie Windows służy do wyświetlania lub zmiany atrybutów plików i folderów. Oto podstawowa składnia polecenia:
            </p>
            <SyntaxHighlighter language='bash' style={vs2015}>
                attrib [atrybuty] [ścieżka_do_pliku]
            </SyntaxHighlighter>
                <p>
                    Główne atrybuty, które można ustawić:
                        <ul>
                            <li><Command>+R</Command> — ustawia atrybut „tylko do odczytu” (Read-only).</li>
                            <li><Command>-R</Command> — usuwa atrybut „tylko do odczytu”.</li>
                            <li><Command>+A</Command> — ustawia atrybut „archiwalny” (Archive), co wskazuje, że plik został zmodyfikowany od ostatniej kopii zapasowej.</li>
                            <li><Command>-A</Command> — usuwa atrybut „archiwalny”.</li>
                            <li><Command>+S</Command> — ustawia atrybut „systemowy” (System), który identyfikuje plik jako plik systemowy.</li>
                            <li><Command>-S</Command> — usuwa atrybut „systemowy”.</li>
                            <li><Command>+H</Command> — ustawia atrybut „ukryty” (Hidden), co ukrywa plik w eksploratorze plików.</li>
                            <li><Command>-H</Command> — usuwa atrybut „ukryty”.</li>
                        </ul>
                </p>
                <h2>Przykłady użycia:</h2>
                <ol>
                    <li>Wyświtelanie atrybutów w bieżącym katalogu:
                        <SyntaxHighlighter language='bash' style={vs2015}>
                        attrib
                        </SyntaxHighlighter>
                    </li>
                    <li>Wyświetlanie atrybutów pliku:
                        <SyntaxHighlighter language='bash' style={vs2015}>
                        attrib C:\sciezka\do\pliku.txt
                        </SyntaxHighlighter>
                    </li>
                    <li>Ustawianie pliku jako ukrytego i systemowego:
                        <SyntaxHighlighter language='bash' style={vs2015}>
                        attrib +H +S C:\sciezka\do\pliku.txt
                        </SyntaxHighlighter>
                    </li>
                    <li>Usuwanie atrybutów „ukryty” i „systemowy”:
                        <SyntaxHighlighter language='bash' style={vs2015}>
                        attrib -H -S C:\sciezka\do\pliku.txt
                        </SyntaxHighlighter>
                    </li>
                    <li>Ustawianie wszystkich plików w folderze jako tylko do odczytu:
                        <SyntaxHighlighter language='bash' style={vs2015}>
                        attrib +R C:\sciezka\do\folderu\*.* /S /D
                        </SyntaxHighlighter>
                    </li>
                </ol>
            </Collapsible>

            <Collapsible title="Polecenie net user" admin>
            <p>Polecenie <Command>net user</Command> w systemie Windows służy do zarządzania kontami użytkowników na komputerze lokalnym lub w domenie. Umożliwia wyświetlanie informacji o kontach użytkowników, tworzenie nowych kont, modyfikowanie istniejących oraz usuwanie kont.</p>
            <p>Oto przykłady użycia polecenia <Command>net user</Command></p>
            <ol>
                <li>Wyświetlenie listy użytkowników:
                    <SyntaxHighlighter language="bash" style={vs2015}>
                        net user
                    </SyntaxHighlighter>
                    <p className='description'>To polecenie wyświetla listę wszystkich kont użytkowników na danym komputerze.</p>
                    <SyntaxHighlighter language='bash' style={vs2015}>
{`C:\\Windows\\system32>net user

Konta użytkowników dla \\\\DESKTOP-VG54LH9

-------------------------------------------------------------------------------
admin                    Administrator            Gość
Konto domyślne           uczen                    WDAGUtilityAccount
Polecenie zostało wykonane pomyślnie.
`}
                    </SyntaxHighlighter>
                </li>
                <li>Wyświetlenie szczegółów konta użytkownika:
                    <SyntaxHighlighter language="bash" style={vs2015}>
                        net user JanKowalski
                    </SyntaxHighlighter>
                    <p className='description'>To polecenie wyświetla szczegóły konta o nazwie „JanKowalski” (np. informacje o tym, czy konto jest aktywne, kiedy wygasa hasło itd.).</p>
                    <SyntaxHighlighter language='bash' style={vs2015}>
{`C:\\Windows\\system32>net user JanKowalski
Nazwa użytkownika                  JanKowalski
Pełna nazwa
Komentarz
Komentarz użytkownika
Kod kraju/regionu                  000 (Domyślne ustawienia systemu)
Konto jest aktywne                 Tak
Wygasanie konta                    Nigdy

Hasło ostatnio ustawiano           23.10.2024 19:40:20
Ważność hasła wygasa               04.12.2024 19:40:20
Hasło może być zmieniane           23.10.2024 19:40:20
Wymagane jest hasło                Tak
Użytkownik może zmieniać hasło     Tak

Dozwolone stacje robocze           Wszystkie
Skrypt logowania
Profil użytkownika
Katalog macierzysty
Ostatnie logowanie                 Nigdy

Dozwolone godziny logowania        Wszystkie

Członkostwa grup lokalnych         *Użytkownicy
Członkostwa grup globalnych        *Brak
Polecenie zostało wykonane pomyślnie.
`}
                    </SyntaxHighlighter>
                </li>
                <li>Tworzenie nowego użytkownika:
                    <SyntaxHighlighter language="bash" style={vs2015}>
                        net user NowyUżytkownik Haslo123 /add
                    </SyntaxHighlighter>
                    <p className='description'>To polecenie tworzy nowe konto użytkownika o nazwie „NowyUżytkownik” z hasłem „Haslo123”. Zamiast podawania hasła w tej samej linii, można użyć symbolu gwiazdki <Command>*</Command>, który spowoduje, że wiersz poleceń poprosi nas o podanie hasła w nowej linii oraz każe nam powtórzyć podane hasło. Tak wpisane hasło nie jest widoczne w CMD, co przekłada się na lepsze bezpieczeństwo i eliminuje popełnienia błędu w haśle.</p>
                </li>
                <li>Usuwanie konta użytkownika:
                    <SyntaxHighlighter language="bash" style={vs2015}>
                        net user NazwaUzytkownika /del
                    </SyntaxHighlighter>
                    <p className='description'>Polecenie usunie konto użytkownika, ale nie usuwa katalogu użytkownika, który znajduje się w <Command>C:\Users</Command>! <br/>Zamiast opcji <Command>/del</Command> można również użyć opcji <Command>/delete</Command>. Są to te same opcje.</p>
                </li>
                <li>Resetowanie / Zmiana hasła użytkownika
                    <SyntaxHighlighter language="bash" style={vs2015}>
                        net user NazwaUzytkownika nowe_hasło   
                    </SyntaxHighlighter>
                    <p className='description'></p>
                </li>
                <p></p>
            </ol>
            <hr/>
            <h2>Zaawansowane opcje polecenia <Command>net user</Command></h2>
            <ol>
                <li>Aktywowanie / dezaktywowanie konta użytkownika
                    <SyntaxHighlighter language="bash" style={vs2015}>
                        net user NazwaUzytkownika /active:WARTOŚĆ   
                    </SyntaxHighlighter>
                    <p className='description'>Opcja <b>ACTIVE</b> przyjmuje 2 wartości: yes - konto jest aktywne, no - konto nie jest aktywne (nie jest widoczne na ekranie logowania oraz nie da się tego konta użyć np. do uruchomienia aplikacji jako administrator.</p>
                </li>
                <li>Ustawienie komentarza
                    <SyntaxHighlighter language="bash" style={vs2015}>
                        net user NazwaUzytkownika /comment:"komentarz"   
                    </SyntaxHighlighter>
                    <p className='description'>Ustawia komentarz konta użytkownika, który jest widoczny po wyświetleniu informacji o koncie lub w przystawce <Command>lusrmgr.msc</Command>. Komentarz nie zmienia żadnego zachowania konta, pozwala po prostu na dodatkowy opis konta.<br/>Komentarz trzeba podać w cudzysłowie!</p>
                </li>
                <li>Ustawienie daty wygaśnięcia konta
                    <SyntaxHighlighter language="bash" style={vs2015}>
                        net user NazwaUzytkownika /expires:WARTOŚĆ   
                    </SyntaxHighlighter>
                    <p className='description'>Pozwoli na ustawienie daty kiedy konto ma wygasnąć - przestać być aktywne. Opcja <b>EXPIRES</b> przyjmuje 2 wartości: datę w formacie <Command>DD/MM/RRRR</Command> lub <Command>NEVER</Command>, które mówi, że konto nigdy nie wygaśnie.<br/>Warto wiedzieć, że format daty jest zależny od regionu i ustawienia daty i godziny na naszym komputerze - dla regionu USA format daty byłby <Command>MM/DD/RRRR</Command>!</p>
                </li>
                <li>Zmiana pełnej nazwy konta użytkownika
                    <SyntaxHighlighter language="bash" style={vs2015}>
                        net user NazwaUzytkownika /fullname:"Pełna nazwa"   
                    </SyntaxHighlighter>
                    <p className='description'>Opcja <b>FULLNAME</b> pozwoli ustawić "Pełną nazwę" konta, która jest oddzielna od nazwy konta ustawionej podczas tworzenia. Pełna nazwa jest wyświetlana między innymi na ekranie logowania. Dzięki temu możemy zmienić mniej czytelną nazwę np. z <Command>Pracownik144598</Command> na <Command>Joanna Kowalska</Command>.<br/>Trzeba pamiętać o zamieszczeniu pełnej nazwy w cudzysłowie!</p>
                </li>
                <li>Możliwość zmiany hasła
                    <SyntaxHighlighter language="bash" style={vs2015}>
                        net user NazwaUzytkownika /passwordchg:WARTOŚĆ   
                    </SyntaxHighlighter>
                    <p className='description'>Opcja <b>PASSWORDCHG</b> pozwoli nam odblokować lub zablokować możliwość zmiany hasła przez użytkownika dla określonego konta. Przyjmuje 2 wartości: YES - użytkownik może zmienić swoje hasło, NO - użytkownik nie może zmienić swojego hasła.</p>
                </li>
                <li>Wymuszenie posiadania hasła
                    <SyntaxHighlighter language="bash" style={vs2015}>
                        net user NazwaUzytkownika /passwrodreq:WARTOŚĆ   
                    </SyntaxHighlighter>
                    <p className='description'>Określa, czy użytkownik tego konta musi mieć hasło lub nie musi. Opcja przyjmuje 2 wartości: YES - użytkownik musi mieć hasło, NO - użytkownik nie musi mieć hasła na koncie.</p>
                </li>
                <li>Wymuszenie zmiany hasła przy następnym logowaniu konta użytkownika
                    <SyntaxHighlighter language="bash" style={vs2015}>
                        net user NazwaUzytkownika /logonpasswordchg:WARTOŚĆ   
                    </SyntaxHighlighter>
                    <p className='description'>Opcja <b>LOGONPASSWORDCHG</b> pozwala nam na wymuszenie zmiany hasła przy następnym logowaniu na określone konto. Przyjmuje 2 wartości: YES - uzytkownik musi zmienić hasło przy następnym logowaniu, NO - użytkownik nie musi zmieniać hasła przy następnym logowaniu. </p>
                </li>
                <li>Godziny logowania
                    <SyntaxHighlighter language="bash" style={vs2015}>
                        net user NazwaUzytkownika /times:WARTOŚĆ   
                    </SyntaxHighlighter>
                    <p className='description'>Opcja ta pozwala na ustawienie przedziałów logowania na dane konto. Możemy określić dany zakres dni tygodnia oraz godziny. Nazwy dni tygodnia podajemy pełną nazwą bez polskich znaków. Oto kilka przykładów:
                        <ul>
                            <li>Zakresy dni: <br/>
                            <ul>
                            <li><Command>net user jajo /times:Poniedzialek-Piatek,7-15</Command> - na konto można się logować tylko od Poniedziałku do Piątku od 7 do 15</li>
                            <li><Command>net user jajo /times:Poniedzialek-Piatek,7-15;Sobota,13-15,Niedziela,17</Command> - na konto można się logować od Poniedziałku do Piątku od 7 do 15, ale też w Soboty od 13 do 15 oraz w Niedzielę od 17-18</li>
                            </ul>
                            Zwróć uwagę na to że godziny od dni oddzielamy przecinkiem <Command>,</Command>, a zakresy oddzielamy średnikiem <Command>;</Command>. Jeżeli podamy tylko jedną godzinę, jak w przykładzie drugim dla Niedzieli, to system Windows automatycznie ustawia czas logowania na 1 godzinę.
                            </li>
                            <li>Bez limitu godzin logowania:<br/>
                                <ul>
                                    <li>
                                        <Command>net user jajo /times:all</Command>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </p>
                </li>
            </ol>
            </Collapsible>

            <Collapsible title="Polecenie net accounts" admin>
                <p>
                Polecenie <Command>net accounts</Command> w systemie Windows służy do zarządzania polityką kont użytkowników, w tym ustawieniami dotyczącymi haseł i logowania. Umożliwia modyfikowanie ustawień takich jak:
                <ul>    
                    <li>Wymagania dotyczące długości i złożoności haseł</li>
                    <li>Okres ważności haseł</li>
                    <li>Historia haseł</li>
                </ul>
                Używanie polecenia <Command>net accounts</Command>
                <ol>
                    <li>Wyświetlenie informacji o obecnej polityce haseł
                        <SyntaxHighlighter language='bash' style={vs2015}>
                            net accounts
                        </SyntaxHighlighter>
                        <p className='description'>Polecenie te wyświetli nam szczegóły o obecnej polityce haseł:
                            <SyntaxHighlighter language='bash' wrapLines={true} style={vs2015}>
{`C:\\Windows\\system32>net accounts
Po jakim czasie od wygaśnięcia czasu wymuszać wylogowanie?:     Nigdy
Minimalny okres ważności hasła (dni):                           0
Maksymalny okres ważności hasła (dni):                          42
Minimalna długość hasła:                                        0
Długość zapamiętywanej historii haseł:                          Brak
Próg blokady:                                                   10
Czas trwania blokady (minuty):                                  10
Okno obserwowania blokady (minuty):                             10
Rola komputera:                                                 STACJA ROBOCZA
Polecenie zostało wykonane pomyślnie.`}
                            </SyntaxHighlighter>
                        </p>
                    </li>
                    <li>Wymuszenie wylogowania po wygaśnięciu konta
                        <SyntaxHighlighter language='powershell' style={vs2015}>
                            net accounts /forcelogoff:WARTOŚĆ
                        </SyntaxHighlighter>
                        <p className='description'>Opcja <b>FORCELOGOFF</b> przyjmuje 2 wartości: minuty - określa ile czasu po wygaśnięciu konta / po minięciu godzin logowania system wymusi wylogowanie, NO - system Windows nie wymusi wylogowania.</p>
                    </li>
                    <li>Minimalna długość hasła
                        <SyntaxHighlighter language='powershell' style={vs2015}>
                            net accounts /minpwlen:DŁUGOŚĆ
                        </SyntaxHighlighter>
                        <p className='description'>Określa minimalną długość haseł. W <b>DŁUGOŚĆ</b> podajemy liczbę z zakresu <Command>0-14</Command>. Domyślna wartość to <Command>6</Command>.</p>
                    </li>
                    <li>Minimalna ważność hasła
                        <SyntaxHighlighter language='powershell' style={vs2015}>
                            net accounts /minpwage:DNI
                        </SyntaxHighlighter>
                        <p className='description'>Blokuje zmianę hasła przez określoną liczbę <b>DNI</b> po zmianie hasła.</p>
                    </li>
                    <li>Maksymalna ważność hasła
                        <SyntaxHighlighter language='powershell' style={vs2015}>
                            net accounts /maxpwage:[DNI|UNLIMITED]
                        </SyntaxHighlighter>
                        <p className='description'>Określa maksymalną liczbę <b>DNI</b> przez ile użytkownik może posiadać to samo hasło. <b>UNLIMITED</b> powoduje, że hasło nie wygaśnie. Dozwolony zakres to <Command>1-999</Command>. Wartość <b>MAXPWAGE</b> nie może być mniejsza niż wartość <b>MINPWAGE</b>!</p>
                    </li>
                    <li>Historia zapamiętywanych haseł
                        <SyntaxHighlighter language='powershell' style={vs2015}>
                            net accounts /uniquepw:LICZBA
                        </SyntaxHighlighter>
                        <p className='description'>Określa historię haseł, których nie można ponownie użyć dopóki są zapisane w historii. <b>LICZBA</b> określa ile ostatnich haseł system Windows pamięta i nie pozwoli ich ustawić ponownie. Domylślnie ta wartość to <Command>0</Command>, a zakres to <Command>0-24</Command></p>
                    </li>
                </ol>
                </p>
            </Collapsible>

            <Collapsible title="Polecenie net localgroup" admin>
                <p>Polecenie <Command>net localgroup</Command> w systemie Windows służy do zarządzania lokalnymi grupami użytkowników na komputerze. Dzięki temu poleceniu można tworzyć, modyfikować oraz wyświetlać grupy użytkowników, a także dodawać lub usuwać użytkowników z tych grup.</p>
                <p>Używanie polecenia <Command>net localgroup</Command></p>
                <ol>
                    <li>Wyświetlenie wszystkich grup
                        <SyntaxHighlighter language='powershell' style={vs2015}>
                            net localgroup
                        </SyntaxHighlighter>
                        <p className='description'>Domyślne system Windows tworzy wiele grup, najważniejszymi są: <Command>Administratorzy</Command> - użytkownicy w tej grupie mają uprawnienia administratora, <Command>Użytkownicy</Command> - domyślna grupa, do której są dodawane wszystkie utworzone konta, również administratorzy, <Command>Goście</Command> - nie wspierana już w Windows 10/11, ale za czasów 7 i wcześniej ta grupa pozwalała na tworzenie kont użytkowników, które czyściły wszystkie zmiany po wylogowaniu się.</p>
                        <SyntaxHighlighter language='bash' style={vs2015}>
{`C:\\Windows\\system32>net localgroup

Aliasy dla \\\\DESKTOP-VG54LH9

-------------------------------------------------------------------------------
*Administratorzy
*Administratorzy funkcji Hyper-V
*Czytelnicy dzienników zdarzeń
*Goście
*Grupa kont zarządzana przez system
*IIS_IUSRS
*Operatorzy konfiguracji sieci
*Operatorzy kopii zapasowych
*Operatorzy kryptograficzni
*Operatorzy pomocy kontroli dostępu
*Replikator
*Użytkownicy
*Użytkownicy DCOM
*Użytkownicy dzienników wydajności
*Użytkownicy monitora wydajności
*Użytkownicy pulpitu zdalnego
*Użytkownicy zaawansowani
*Użytkownicy zarządzania zdalnego
*Właściciele urządzenia
Polecenie zostało wykonane pomyślnie.
`}
                    </SyntaxHighlighter>
                    </li>
                    <li>Wyświetlenie informacji o danej grupie
                        <SyntaxHighlighter language='bash' style={vs2015}>
                            net localgroup nazwa_grupy
                        </SyntaxHighlighter>
                        <p className='description'>Wyświetla takie informacje jak: nazwa grupy, komentarz do gruoy, członków grupy.</p>
                        <SyntaxHighlighter language='bash' style={vs2015}>
{`C:\\Windows\\system32>net localgroup Użytkownicy
Nazwa aliasu     Użytkownicy
Komentarz        Użytkownicy nie mogą przeprowadzać przypadkowych ani celowych zmian na poziomie całego systemu i mogą uruchamiać większość aplikacji.

Członkowie

-------------------------------------------------------------------------------
JanKowalski
uczen
ZARZĄDZANIE NT\\INTERAKTYWNA
ZARZĄDZANIE NT\\Użytkownicy uwierzytelnieni
Polecenie zostało wykonane pomyślnie.
`}
                    </SyntaxHighlighter>
                    </li>
                    <li>Tworzenie nowej grupy
                        <SyntaxHighlighter language='powershell' style={vs2015}>
                            net localgroup nazwa_grupy /add
                        </SyntaxHighlighter>
                    </li>
                    <li>Usuwanie grupy
                        <SyntaxHighlighter language='bash' style={vs2015}>
                            net localgroup nazwa_grupy /del
                        </SyntaxHighlighter>
                    </li>
                    <li>Dodawania użytkownika do grupy
                        <SyntaxHighlighter language='bash' style={vs2015}>
                            net localgroup nazwa_grupy nazwa_użytkownika /add
                        </SyntaxHighlighter>
                    </li>
                    <li>Usuwanie użytkownika z grupy
                        <SyntaxHighlighter language='bash' style={vs2015}>
                            net localgroup nazwa_grupy nazwa_użytkownika /del
                        </SyntaxHighlighter>
                    </li>
                    <li>Dodanie komentarza do grupy
                        <SyntaxHighlighter language='bash' style={vs2015}>
                            net localgroup nazwa_grupy /comment:"Komentarz"
                        </SyntaxHighlighter>
                    </li>
                </ol>
            </Collapsible>

            <hr style={{width:'100%'}}/>
            <h1>Zadania</h1>
            <Collapsible title='Zadanie z CMD'>
            <h1>Polecenia CMD na maszynie wirtualnej</h1>
            <a href='https://cdn.sjarecki.pl/sso-ppo/pliki.zip' className='download-bttn'><FontAwesomeIcon icon={faDownload} /> Pobierz pliki do zadania</a>
            <p>Pliki do zadania pobierz na hoście i wrzuć na maszynę wirtualną tworząc folder udostępniony tak jak poniżej</p>
            <Collapsible style={{width:'80%'}} title="Tworzenie folderu udostępnionego na VirtualBox" small>
                <ResultImage imgPath='zadanie-2.webm' video/>
            </Collapsible>
            <p><b style={{color:'red'}}>Każde polecenie udokumentuj zrzutem ekranowym całego okna CMD</b>. Cały plik sprawozdania nazwij swoim nazwiskiem, klasą i grupą np. <b>ćw_3_Nowakowski_2K1</b>. Ten dokument oraz plik <b>spis_nazwisko.txt</b> (pkt. 27) zapisz na serwer FTP <Command>ftp://10.1.205.50</Command>.</p>
                <ol>
                    {tasks.map((task, index) => (
                    <li key={index}>
                        <ReactMarkdown components={{code({ children }) {return <Command>{children}</Command>;},}}>{task.description}</ReactMarkdown> <KeyShortcut>{task.command}</KeyShortcut>
                        {task.image && (
                        <ResultImage imgPath={task.image}></ResultImage>
                        )}
                    </li>
                    ))}
                </ol>
            </Collapsible>

            <Collapsible title="Zadanie - CMD, tworzenie użytkowników i grup lokalnych oraz przystawki">
            <p><b>Zrzuty umieść w dokumencie Worda. Nazwa sprawozdania: <Command>grupy_nazwisko_klasaGrupa.docx</Command> </b></p>
            <p>Twoim zadaniem będzie utworzenie kont użytkowników oraz grup lokalnych. Następnie dodasz konta użtkowników do nowo utworzonych grup, a na końcu przejdziesz do przystawki <Command>secpol.msc</Command> i zmienisz kilka zasad.</p>
            <p><b style={{color:'red'}}>Każdy punkt w zadaniu dokumentuj zrzutem ekranu, chyba że w punkcie jest napisane, że nie trzeba. Na zrzutach z konsoli ma być widoczny pasek tytułowy konsoli cmd.</b></p>
            <ol>
            {zadania2.map((task, index) => (
                <li key={index}>
                    <ReactMarkdown components={{code({ children }) {return <Command>{children}</Command>;},}}>{task.description}</ReactMarkdown> <KeyShortcut>{task.command}</KeyShortcut>
                    {task.image && (
                    <ResultImage imgPath={task.image}></ResultImage>
                    )}
                </li>
            ))}
            </ol>
            <p>Wykonane zadanie prześlij na lokalny serwer FTP <Command>ftp:\\10.1.205.50</Command> do folderu <Command>Sprawozdania</Command>.</p>
            </Collapsible>

            <Collapsible title="Zadanie - partycjonowanie dysku">
            <p>Na początku zapoznaj się z nowymi treściami z napisem <span style={{color:'lime',fontWeight:'bold'}}>NEW</span></p>
            <p><b>Zrzuty umieść w dokumencie Worda. Nazwa sprawozdania: <Command>partycjonowanie_nazwisko_klasaGrupa.docx</Command> </b></p>
            <p>Twoim zadaniem będzie zmniejszenie partycji <Command>C:\</Command> oraz utworzenie 3 nowych partycji o różnych systemach plików i jednostkach alokacji.</p>
            <p><b style={{color:'red'}}>Każdy punkt w zadaniu dokumentuj zrzutem ekranu, chyba że w punkcie jest napisane, że nie trzeba. Na zrzutach z konsoli ma być widoczny pasek tytułowy konsoli cmd.</b></p>
            <ol>
            {zadania3.map((task, index) => (
                <li key={index}>
                    <ReactMarkdown components={{code({ children }) {return <Command>{children}</Command>;},}}>{task.description}</ReactMarkdown> <KeyShortcut>{task.command}</KeyShortcut>
                    {task.image && (
                    <ResultImage imgPath={task.image}></ResultImage>
                    )}
                </li>
            ))}
            </ol>
            <p>Wykonane zadanie prześlij na lokalny serwer FTP <Command>ftp:\\10.1.205.50</Command> do folderu <Command>Sprawozdania</Command>.</p>
            </Collapsible>
      </div>
    </div>
  );
};

export default SSO;
